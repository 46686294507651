<template>
  <div class="container fluid">
    <div class="static-page">
      <div class="header">
        <img
          src="@/assets/img/auth-shape-top.svg"
          alt="shape of dot"
          class="header-shape-top"
          loading="lazy"
        />
        <br />
        <h1 class="header-title">Cookie Policy</h1>
      </div>
      <br />
      <h2 style="font-weight: bold; font-size: 22px">Cookie Policy</h2>
      <br />
      <p class="privacy">
        We use cookies to help improve your experience of our website at
        <a href="https://vgang.io">https://vgang.io</a>. This cookie policy is
        part of VGANG&#39;s privacy policy. It covers the use of cookies between
        your device and our site.
      </p>
      <p class="privacy">
        We also provide basic information on third-party services we may use,
        who may also use cookies as part of their service. This policy does not
        cover their cookies.
      </p>
      <p class="privacy">
        If you don’t wish to accept cookies from us, you should instruct your
        browser to refuse cookies from
        <a href="https://vgang.io">https://vgang.io</a>. In such a case, we may
        be unable to provide you with some of your desired content and services.
      </p>
      <br />
      <h3 style="font-weight: bold">What is a cookie?</h3>
      <p class="privacy">
        A cookie is a small piece of data that a website stores on your device
        when you visit. It typically contains information about the website
        itself, a unique identifier that allows the site to recognize your web
        browser when you return, additional data that serves the cookie’s
        purpose, and the lifespan of the cookie itself.
      </p>
      <p class="privacy">
        Cookies are used to enable certain features (e.g. logging in), track
        site usage (e.g. analytics), store your user settings (e.g. time zone,
        notification preferences), and to personalize your content (e.g.
        advertising, language).
      </p>
      <p class="privacy">
        Cookies set by the website you are visiting are usually referred to as
        first-party cookies. They typically only track your activity on that
        particular site.
      </p>
      <p class="privacy">
        Cookies set by other sites and companies (i.e. third parties) are called
        third-party cookies They can be used to track you on other websites that
        use the same third-party service.
      </p>
      <br />
      <h3 style="font-weight: bold">Types of cookies and how we use them</h3>
      <br />
      <h4 style="font-weight: bold">Essential cookies</h4>
      <p class="privacy">
        Essential cookies are crucial to your experience of a website, enabling
        core features like user logins, account management, shopping carts, and
        payment processing.
      </p>
      <p class="privacy">
        We use essential cookies to enable certain functions on our website.
      </p>
      <br />
      <h4 style="font-weight: bold">Performance cookies</h4>
      <p class="privacy">
        Performance cookies track how you use a website during your visit.
        Typically, this information is anonymous and aggregated, with
        information tracked across all site users. They help companies
        understand visitor usage patterns, identify and diagnose problems or
        errors their users may encounter, and make better strategic decisions in
        improving their audience’s overall website experience. These cookies may
        be set by the website you’re visiting (first-party) or by third-party
        services. They do not collect personal information about you.
      </p>
      <p class="privacy">We use performance cookies on our site.</p>
      <br />
      <h4 style="font-weight: bold">Functionality cookies</h4>
      <p class="privacy">
        Functionality cookies are used to collect information about your device
        and any settings you may configure on the website you’re visiting (like
        language and time zone settings). With this information, websites can
        provide you with customized, enhanced, or optimized content and
        services. These cookies may be set by the website you’re visiting
        (first-party) or by third-party services.
      </p>
      <p class="privacy">
        We use functionality cookies for selected features on our site.
      </p>
      <br />
      <h4 style="font-weight: bold">Targeting/advertising cookies</h4>
      <p class="privacy">
        Targeting/advertising cookies help determine what promotional content is
        most relevant and appropriate to you and your interests. Websites may
        use them to deliver targeted advertising or limit the number of times
        you see an advertisement. This helps companies improve the effectiveness
        of their campaigns and the quality of content presented to you. These
        cookies may be set by the website you’re visiting (first-party) or by
        third-party services. Targeting/advertising cookies set by third-parties
        may be used to track you on other websites that use the same third-party
        service.
      </p>
      <p class="privacy">We use targeting/advertising cookies on our site.</p>
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: "CookiePolicy",
  metaInfo: {
    title: "vgang|Cookie Policy",
    meta: [
      {
        name: "description",
        content:
          "Conditioned on the terms and conditions of this Agreement and payment of the Fees, VGANG shall make the Service available to Supplier during the term of the Supplier’s subscription and provide basic support to Supplier in respect to Supplier’s permitted use of the Service. ",
      },
    ],
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped lang="scss">
.static-page {
  margin-top: 175px;
}
.privacy {
  margin-top: 0pt;
  margin-bottom: 0pt;
  font-size: 13pt;
}
.static-page .header {
  background: #eefbfc;
  position: relative;
  height: 280px;
  margin-top: -72px;
  padding-top: 48px;
}
</style>
